import React from 'react';
import './header.css';
import CTA from './CTA';
import ME from '../../assets/me-01.png';
import HeaderSocials from './HeaderSocials';
import { text } from '../../data/languages';

const Header = ({ language, changeLanguage }) => {
	return (
		<header>
			<div className='container container__header' id='header'>
				<h5>{text[language].header.h5_1}</h5>
				<h1>Daniel Bauer</h1>
				<h5 className='text-light'>{text[language].header.h5_2}</h5>
				<HeaderSocials changeLanguage={changeLanguage} />
				<CTA language={language} />
				<div className='me'>
					<img src={ME} alt='Me' />
				</div>

				<a href='#contact' className='scroll__down'>
					{text[language].header.scrollDown}
				</a>
			</div>
		</header>
	);
};

export default Header;
