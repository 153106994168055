import React from 'react';
import './contact.css';
import { MdOutlineEmail } from 'react-icons/md';
// import { RiMessengerLine } from 'react-icons/ri';
import { BsWhatsapp } from 'react-icons/bs';
import { useRef } from 'react';
import emailjs from 'emailjs-com';
import { text } from '../../data/languages';

const Contact = ({ language }) => {
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				process.env.REACT_APP_EMAILJS_SERVICE_ID,
				process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
				form.current,
				process.env.REACT_APP_EMAILJS_PUBLIC_KEY
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		e.target.reset();
	};

	return (
		<section id='contact'>
			<h5>{text[language].contact.h5_1}</h5>
			<h2>{text[language].contact.h2_1}</h2>

			<div className='container contact__container'>
				<div className='contact__options'>
					<article className='contact__option'>
						<MdOutlineEmail className='contact__option-icon' />
						<h4>{text[language].contact.h4_1_1}</h4>
						{/* <h5>Achtung Spam</h5> */}
						<a
							href='mailto:info@danielbauer.dev'
							target='_blank'
							rel='noopener noreferrer'
						>
							{text[language].contact.a_1_1}
						</a>
					</article>
					{/* <article className='contact__option'>
						<RiMessengerLine className='contact__option-icon' />
						<h4>Messenger:</h4>
						<h5>Test</h5>
						<a
							href='https://m.me/daniel.bauer.5648'
							target='_blank'
							rel='noopener noreferrer'
						>
							Send a message
						</a>
					</article> */}
					<article className='contact__option'>
						<BsWhatsapp className='contact__option-icon' />
						<h4>{text[language].contact.h4_2_1}</h4>
						<h5>{text[language].contact.h5_2_1}</h5>
						<a
							href='https://wa.me/+491783428662'
							target='_blank'
							rel='noopener noreferrer'
						>
							{text[language].contact.a_2_1}
						</a>
					</article>
				</div>
				{/* END OF CONTACT OPTIONS */}
				<form ref={form} onSubmit={sendEmail}>
					<input
						type='text'
						name='name'
						placeholder={text[language].contact.input_1}
						required
					/>
					<input
						type='email'
						name='email'
						placeholder={text[language].contact.input_2}
						required
					/>
					<textarea
						name='message'
						rows='7'
						placeholder={text[language].contact.input_3}
						required
					></textarea>
					<button type='submt' className='btn btn-primary'>
						{text[language].contact.btnText}
					</button>
				</form>
			</div>
		</section>
	);
};

export default Contact;
