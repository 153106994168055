import './impressum.css'; // Importieren Sie die CSS-Date
import React, { useState, useEffect } from 'react';
import marked from 'marked';

const Impressum = () => {
	const [markdown, setMarkdown] = useState('');

	useEffect(() => {
		const fetchMarkdown = async () => {
			const path = './data/impressumContent.md';
			const response = await fetch(path);
			const text = await response.text();
			setMarkdown(text);
		};

		fetchMarkdown();
	}, []);

	const html = marked(markdown);

	return (
		<article
			className='impressum__article'
			dangerouslySetInnerHTML={{ __html: html }}
		/>
	);
};

export default Impressum;
