// Code Für Bilder mit Hover effekt.

import React from 'react';
import './portfolio.css';
import { text } from '../../data/languages';
import { data } from '../../data/portfolio';
// console.log(data);

const Portfolio = ({ language }) => {
	return (
		<section id='portfolio'>
			<h5>{text[language].portfolio.h5_1}</h5>
			<h2>{text[language].portfolio.h2_1}</h2>

			<div className='container portfolio__container'>
				{data.map(
					({
						id,
						title,
						description,
						technologies,
						github,
						demo,
					}) => {
						return (
							<article key={id} className='portfolio__item'>
								<div
									className={`portfolio__item-image portfolio__item-image-${id}`} // Add class for image hover effect for showing second image
								></div>
								{/* Show data title depending on lanuage */}
								<h4>
									{language === 'de' ? title[0] : title[1]}
								</h4>
								<p className='portfolio__item-technologies text-light'>
									<small>{technologies}</small>
								</p>

								<div className='portfolio__item-description text-light'>
									<small>
										{language === 'de' ? (
											<div
												dangerouslySetInnerHTML={{
													__html: description[0],
												}}
											/>
										) : (
											<div
												dangerouslySetInnerHTML={{
													__html: description[1],
												}}
											/>
										)}
									</small>
								</div>
								<div className='portfolio__item-cta'>
									<a
										href={github}
										className='btn'
										target='_blank'
										rel='noopener noreferrer'
									>
										Github
									</a>
									<a
										href={demo}
										className='btn btn-primary'
										target='_blank'
										rel='noopener noreferrer'
									>
										Live Demo
									</a>
								</div>
							</article>
						);
					}
				)}
			</div>
		</section>
	);
};

export default Portfolio;
