import React from 'react';
import './headerSocials.css';
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';

const HeaderSocials = ({ changeLanguage }) => {
	return (
		<div className='header__socials'>
			<a
				href='https://www.linkedin.com/in/daniel-bauer-munich/'
				target='_blank'
				rel='noopener noreferrer'
			>
				<BsLinkedin size={24} />
			</a>
			<a
				href='https://github.com/Shrike717'
				target='_blank'
				rel='noopener noreferrer'
			>
				<FaGithub size={24} />
			</a>

			<button
				className='header__icon-de'
				onClick={() => changeLanguage('de')}
			></button>
			<button
				className='header__icon-eng'
				onClick={() => changeLanguage('en')}
			></button>
		</div>
	);
};

export default HeaderSocials;
