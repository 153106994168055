import React from 'react';
import './footer.css';
import { FaFacebookF } from 'react-icons/fa';
import { FiInstagram } from 'react-icons/fi';
// import { IoLogoTwitter } from 'react-icons/io';
import { text } from '../../data/languages';

import { Link, useNavigate } from 'react-router-dom';

const Footer = ({ language }) => {
	const navigate = useNavigate();

	// Diese Funktion überprüft Ob ich mich auf der Homepage oder auf einer anderen Seite befinde.
	// Wenn ich mich auf der Homepage befinde, springe ich miit den Links nur zu den Ankern.
	// Wenn ich mich auf einer anderen Seite befinde, springe ich zuerst zur Homepage und dann zu den Ankern.

	const handleClick = (event, section) => {
		event.preventDefault();
		if (window.location.pathname !== '/') {
			navigate('/');
			setTimeout(() => {
				const element = document.getElementById(section);
				if (element) element.scrollIntoView();
			}, 100);
		} else {
			const element = document.getElementById(section);
			if (element) element.scrollIntoView();
		}
	};
	return (
		<footer id='footer'>
			{/* eslint-disable-next-line */}
			<Link
				to='/'
				className='footer__logo'
				onClick={() => window.scrollTo(0, 0)}
			>
				{text[language].footer.a_1}
			</Link>
			<ul className='permalinks'>
				<li>
					{/* eslint-disable-next-line */}
					<a
						href='#'
						onClick={(event) => {
							handleClick(event, 'home');
							window.scrollTo(0, 0);
						}}
					>
						{text[language].footer.li_a_1}
					</a>
				</li>
				<li>
					{/* eslint-disable-next-line */}
					<a
						href='#about'
						onClick={(event) => handleClick(event, 'about')}
					>
						{text[language].footer.li_a_2}
					</a>
				</li>
				<li>
					{/* eslint-disable-next-line */}
					<a
						href='#experience'
						onClick={(event) => handleClick(event, 'experience')}
					>
						{text[language].footer.li_a_3}
					</a>
				</li>
				<li>
					{/* eslint-disable-next-line */}
					<a
						href='#portfolio'
						onClick={(event) => handleClick(event, 'portfolio')}
					>
						{text[language].footer.li_a_4}
					</a>
				</li>
				<li>
					{/* eslint-disable-next-line */}
					<a
						href='#contact'
						onClick={(event) => handleClick(event, 'contact')}
					>
						{text[language].footer.li_a_5}
					</a>
				</li>
				<li>
					<Link to='/impressum' onClick={() => window.scrollTo(0, 0)}>
						{text[language].footer.li_a_6}
					</Link>
				</li>
			</ul>

			<div className='footer__socials'>
				<a
					href='https://www.facebook.com/daniel.bauer.5648/'
					target='_blank'
					rel='noopener noreferrer'
				>
					<FaFacebookF />
				</a>
				<a
					href='https://www.instagram.com/daniel_bauer_71'
					target='_blank'
					rel='noopener noreferrer'
				>
					<FiInstagram />
				</a>
				{/* <a
					href='https://twitter.com'
					target='_blank'
					rel='noopener noreferrer'
				>
					<IoLogoTwitter />
				</a> */}
			</div>
			<div className='footer__copyright'>
				<small>&copy; {text[language].footer.small_1}</small>
			</div>
		</footer>
	);
};

export default Footer;
