import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/header/Header';
import Nav from './components/nav/Nav';
import About from './components/about/About';
import Experience from './components/experience/Experience';
// import Services from './components/services/Services';
import Portfolio from './components/portfolio/Portfolio';
// import Testimonials from './components/testimonials/Testimonials';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Impressum from './components/impressum/Impressum'; // Import your Impressum component

const App = () => {
	const [language, setLanguage] = useState('de'); // 'en' for English, 'de' for German

	const changeLanguage = (newLanguage) => {
		setLanguage(newLanguage);
	};

	return (
		<Router>
			<Routes>
				<Route
					path='/impressum'
					element={
						<>
							<Nav />
							<Impressum language={language} />
							<Footer language={language} />
						</>
					}
				/>
				<Route
					path='/*'
					element={
						<>
							<Header
								language={language}
								changeLanguage={changeLanguage}
							/>
							<Nav />
							<About language={language} />
							<Experience language={language} />
							{/* <Services /> */}
							<Portfolio language={language} />
							{/* <Testimonials /> */}
							<Contact language={language} />
							<Footer language={language} />
						</>
					}
				/>
			</Routes>
		</Router>
	);
};

export default App;
