//

// Texte für beide Sprachen in einem Objekt
export const text = {
	// *********** DEUTSCH ***********
	de: {
		header: {
			h5_1: 'Hallo, ich bin',
			h5_2: 'Junior Fullstack Entwickler',
			btnText: 'Kontakt',
			scrollDown: 'Nach Unten',
		},
		about: {
			h5_1: 'Mein Hintergrund',
			h2_1: 'Über Mich',
			h5_2: 'Erfahrung',
			small_1: '1+ Jahr Studium',
			h5_3: 'Kunden',
			small_2: 'Hoffentlich bald',
			h5_4: 'Projekte',
			small_3: '5+ Abgeschlossen',
			p_1: 'Herzlich willkommen auf meiner Reise durch die Welt der Webentwicklung!',
			p_2: 'Ich bin ein Junior-Webentwickler aus München. Nachdem ich zuvor lange in der digitalen Postproduktion beschäftigt war, habe ich den Schritt in die dynamische Welt der Full-Stack Webentwicklung gewagt.',
			p_3: 'Ich begann mit einem intensiven Bootcamp bei LeWagon in München, in welchem ich die Grundlagen gelegt habe, interaktive digitale Erlebnisse zu schaffen.',
			p_4: 'Um meine Fähigkeiten noch weiter zu vertiefen, habe ich mich der DevCraft Akademie angschlossen, um den ständig wachsenden Anforderungen der Tech-Landschaft gerecht zu werden.',
			p_5: 'Ich bin offen für neue Herausforderungen und hoffe, meine Fähigkeiten bald in einem Job als Junior-Entwickler unter Beweis stellen zu können.',
			p_6: 'Wenn ich nicht gerade Code schreibe, findest du mich wahrscheinlich auf Entdeckungstouren in der Natur, beim Wandern, Biken, oder beim Bogenschießen.',
			p_7: 'Begleite mich auf dieser aufregenden Reise, auf der Technologie und Kreativität aufeinandertreffen.  Lass uns gemeinsam etwas erschaffen!',
			btnText: 'Kontakt',
		},
		experience: {
			h5_1: 'Was Ich Kann',
			h2_1: 'Kenntnisse',
			// Frontend
			h3_1: 'Frontend',
			h4_1_1: 'HTML',
			small_1_1: 'Fortgeschritten',
			h4_1_2: 'CSS',
			small_1_2: 'Medium',
			h4_1_3: 'JavaScript',
			small_1_3: 'Medium',
			h4_1_4: 'Bootstrap',
			small_1_4: 'Medium',
			h4_1_5: 'Tailwind',
			small_1_5: 'Grundlagen',
			h4_1_6: 'React',
			small_1_6: 'Medium',
			h4_1_7: 'Gsap',
			small_1_7: 'Grundlagen',
			h4_1_8: 'ThreeJS',
			small_1_8: 'Grundlagen',
			// Backend
			h3_2: 'Backend',
			h4_2_1: 'NodeJS',
			small_2_1: 'Medium',
			h4_2_2: 'MySQL',
			small_2_2: 'Grundlagen',
			h4_2_3: 'Ruby',
			small_2_3: 'Grundlagen',
			h4_2_4: 'PostgreSQL',
			small_2_4: 'Grundlagen',
			h4_2_5: 'NextJS',
			small_2_5: 'Grundlagen',
			h4_2_6: 'MongoDB',
			small_2_6: 'Grundlagen',
			// Tools
			h3_3: 'Tools',
			h4_3_1: 'Github',
			small_3_1: 'Grundlagen',
			h4_3_2: 'Git',
			small_3_2: 'Grundlagen',
		},
		portfolio: {
			h5_1: 'Meine Projekte',
			h2_1: 'Portfolio',
		},
		contact: {
			h5_1: 'So Erreichst Du Mich',
			h2_1: 'Kontakt',
			h4_1_1: 'Email:',
			a_1_1: 'Nachricht Senden',
			h4_2_1: 'WhatsApp:',
			h5_2_1: '+491783428662',
			a_2_1: 'Nachricht Senden',
			input_1: 'Dein Vor- und Nachname',
			input_2: 'Deine Email',
			input_3: 'Deine Nachricht',
			btnText: 'Nachricht Senden',
		},
		footer: {
			a_1: 'DANIEL BAUER',
			li_a_1: 'Home',
			li_a_2: 'Über Mich',
			li_a_3: 'Kenntnisse',
			li_a_4: 'Portfolio',
			li_a_5: 'Kontakt',
			li_a_6: 'Impressum',
			small_1: '2024 Daniel Bauer Alle Rechte vorbehalten.',
		},
	},
	// *********** ENGLISH ***********
	en: {
		header: {
			h5_1: "Hello I'm",
			h5_2: 'Junior Fullstack Developer',
			btnText: 'Contact',
			scrollDown: 'Scroll Down',
		},
		about: {
			h5_1: 'Get To Know',
			h2_1: 'About Me',
			h5_2: 'Experience',
			small_1: '1+ Year Studying',
			h5_3: 'Clients',
			small_2: 'Hopefullly Soon',
			h5_4: 'Projects',
			small_3: '5+ Completed',
			p_1: 'Welcome to my web development journey!',
			p_2: 'I am a junor web developer based in Munich. Formerly immersed in the world of digital postproduction, I decided to take a bold leap into the dynamic realm of Full-Stack Web Development.',
			p_3: 'Embarking on this exciting adventure, I honed my coding skills through an intensive bootcamp at LeWagon in Munich, where I discovered the power of crafting interactive digital experiences.',
			p_4: 'Eager to push my capabilities even further, I enrolled in the DevCraft Academy, sculpting my skills to meet the ever-evolving demands of the tech landscape.',
			p_5: 'I am open to new challenges and hope to soon be able to showcase my skills in a job as a junior developer.',
			p_6: "When I'm not busy coding, you'll probably find me in the great outdoors, hiking, cycling, or practicing archery.",
			p_7: "Join me on this exciting journey where technology and creativity meet.  Let's create something together!",
			btnText: 'Contact',
		},
		experience: {
			h5_1: 'What Skills I Have',
			h2_1: 'My Experience',
			// Frontend
			h3_1: 'Frontend',
			h4_1_1: 'HTML',
			small_1_1: 'Experienced',
			h4_1_2: 'CSS',
			small_1_2: 'Intermediate',
			h4_1_3: 'JavaScript',
			small_1_3: 'Intermediate',
			h4_1_4: 'Bootstrap',
			small_1_4: 'Intermediate',
			h4_1_5: 'Tailwind',
			small_1_5: 'Basic',
			h4_1_6: 'React',
			small_1_6: 'Intermediate',
			h4_1_7: 'Gsap',
			small_1_7: 'Basic',
			h4_1_8: 'ThreeJS',
			small_1_8: 'Basic',
			// Backend
			h3_2: 'Backend',
			h4_2_1: 'NodeJS',
			small_2_1: 'Intermediate',
			h4_2_2: 'MySQL',
			small_2_2: 'Basic',
			h4_2_3: 'Ruby',
			small_2_3: 'Basic',
			h4_2_4: 'PostgreSQL',
			small_2_4: 'Basic',
			h4_2_5: 'NextJS',
			small_2_5: 'Basic',
			h4_2_6: 'MongoDB',
			small_2_6: 'Basic',
			// Tools
			h3_3: 'Tools',
			h4_3_1: 'Github',
			small_3_1: 'Basic',
			h4_3_2: 'Git',
			small_3_2: 'Basic',
		},
		portfolio: {
			h5_1: 'My Recent Work',
			h2_1: 'Portfolio',
		},
		contact: {
			h5_1: 'Get In Touch',
			h2_1: 'Contact Me',
			h4_1_1: 'Email:',
			a_1_1: 'Send a message',
			h4_2_1: 'WhatsApp:',
			h5_2_1: '+491783428662',
			a_2_1: 'Send a message',
			input_1: 'Your Full Name',
			input_2: 'Your Email',
			input_3: 'Your Message',
			btnText: 'Send Message',
		},
		footer: {
			a_1: 'DANIEL BAUER',
			li_a_1: 'Home',
			li_a_2: 'About',
			li_a_3: 'Experience',
			li_a_4: 'Portfolio',
			li_a_5: 'Contact',
			li_a_6: 'Imprint',
			small_1: ' 2024 Daniel Bauer All rights reserved.',
		},
	},
};
