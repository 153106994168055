// Code Für Bilder mit Hover effekt.
// Export portfolio data

export const data = [
    {
        id: 1,
        title: [
            'Die App Iphone ist ein Klon der Apple-Website für das iPhone 15 Pro',
            'The app Iphone is a clone of the Apple website for the iPhone 15 Pro',
        ],

        technologies: 'React | Vite | Tailwind | Gsap | ThreeJS | Sentry',

        description: [
            `Iphone ist eine E-Commerce-App, die speziell für den Verkauf des iPhone 15 Pro entwickelt wurde. <br>
            Diese App ist ein Klon der offiziellen Apple-Website für das iPhone 15 Pro und wurde als Übungsprojekt erstellt, um zu lernen, wie man Animationen mit GSAP erstellt und ein 3D-Modell des iPhone mit ThreeJS und React Three Fiber (R3F) einbindet. <br>
            Die Hauptkomponenten sind ein animierter Video-Slider sowie ein 3-D Modell, mit welchem der User interagieren kann. <br>
            Das Projekt wurde mithilfe eines Tutorials von JS Mastery erstellt.`,

            `Iphone is an e-commerce app specifically developed for selling the iPhone 15 Pro. <br>
            This app is a clone of the official Apple website for the iPhone 15 Pro and was created as a practice project to learn how to create animations using GSAP and incorporate a 3D model of the iPhone using ThreeJS and React Three Fiber (R3F). <br>
            The main components are an animated video slider and a 3D model with which the user can interact. <br>
            The project was created using a tutorial by JS Mastery.`,
        ],

        github: 'https://github.com/Shrike717/ecommerce-iphone-react',
        demo: 'https://ecommerce-iphone-react.vercel.app',
    },
    {
        id: 2,
        title: [
            'DevFlow ist eine Online-Plattform für Entwicklerwissen',
            'DevFlow is an online platform for developer knowledge',
        ],

        technologies: 'NextJS 14 | Typescript | Tailwind | ShadCn | Clerk | MongoDB | Chat GTP',

        description: [
            `Die Plattform konzentriert sich auf das gemeinschaftliche Lösen von Programmier- und Entwicklungsherausforderungen.<br>
            Nutzer können Fragen stellen, die Community aus Entwicklern bietet Antworten und Lösungen dafür.<br>
            Es gibt eine Custom - sowie eine OAuth Authentifizierung mit Google oder Github sowie eine Nutzerverwaltung mittels Clerk.<br>
            Es gibt ein Recommendation System, welches Interaktionen von Nutzern auswerten kann und Beiträge zeigen kann, mit denen besonders viel interagiert wurde beziehungsweise die oft angeschaut wurden.<br>
            Es gibt ein Reputations System, mit dem die Aktivität von Nutzern auf der Plattform gemessen und belohnt werden kann.<br>
            User können sich auf der Plattform durch die Anbindung der Chat GPT 3.5 API eine KI generierte Antwort erstellen, die dann als Basis für eine Antwort auf eine Frage benutzt werden kann.<br>
            Die App wurde mithilfe des Ultimate NextJs Kurses von JS Mastery erstellt.`,

            `The platform focuses on collaboratively solving programming and development challenges. <br>
            Users can ask questions and the community of developers provides answers and solutions. <br>
            There is custom and OAuth authentication with Google or Github as well as user management via Clerk. <br>
            There is a recommendation system that can evaluate user interactions and show posts that have been interacted with particularly often or that have been viewed frequently. <br>
            There is a reputation system with which the activity of users on the platform can be measured and rewarded. <br>
            Users can create an AI-generated answer on the platform by connecting to the Chat GPT 3.5 API, which can then be used as the basis for an answer to a question. <br>
            The app was created using the Ultimate NextJs course from JS Mastery.`,
        ],

        github: 'https://github.com/Shrike717/NEXTJS-JSM-ULTIMATE-NEXT--COURSE-JS-MASTERY',
        demo: 'https://nextjs-jsm-ultimate-next-course-js-mastery.vercel.app',
    },
	{
        id: 3,
        title: [
            'Yoom ist ein Zoom Klon für Video Konferenzen',
            'Yoom is a Zoom clone for video conferences',
        ],
        technologies: 'Next14 | TypeScript | Tailwind | ShadCn | Clerk | Stream',

        description: [
            `Yoom ist eine Kommunikations-App, die für Videokonferenzen entwickelt wurde. Sie ermöglicht es Benutzern, Videoanrufe zu führen und miteinander zu kommunizieren. <br>
			Die App wurde mit Next 14 und Tailwind erstellt. Als UI-Framework kam ShadCn zum Einsatz. <br>
			Es gibt eine Custom - sowie eine OAuth Authentifizierung mit Google, Github oder LinkedIn sowie eine Nutzerverwaltung über Clerk. <br>
			Für das Audio- und Video-Streaming wurde die SDK Stream verwendet.
			Diese ermöglicht die Erstellung von WebSocket-Verbindungen für die Kommunikation mit dem Stream-Backend. <br>
			Man kann Video Calls per Kalender planen oder sofort starten.  Über Links können Teilnehmer zu den Video Calls eingeladen werden.Es gibt auch eine Funktion zum Aufzeichnen und Verwalten der Video Calls. <br>
			Das Projekt wurde mit Hilfe eines Tutorials JS Mastery erstellt.`,
			
            `Yoom is a communication app that was developed for video conferencing.<br> 
            It allows users to make video calls and communicate with each other.<br>
			The app was created with Next 14 and Tailwind. ShadCn was used as the UI framework.<br>
			There is custom and OAuth authentication with Google, Github or LinkedIn as well as user management via Clerk.<br>
			The SDK Stream was used for audio and video streaming.
			This enables the creation of WebSocket connections for communication with the stream backend.
			Video calls can be scheduled via a calendar or started immediately.  Participants can be invited to the video calls via links.<br>
            There is also a function for recording and managing the video calls.<br>
			The project was created with the help of a JS Mastery tutorial.`,
        ],
        github: 'https://github.com/Shrike717/communication-yoom-nextjs',
        demo: 'https://communication-yoom-nextjs.vercel.app',
    },
    {
        id: 4,
        title: [
            'Travel Couch Surfing App um weltweit Unterkünfte zu finden',
            'Travel Couch Surfing App to find accommodation worldwide',
        ],
        technologies: 'React | MUI5 | NodeJS Express | JWT | MongoDB | Firebase | Mapbox',

        description: [
            `Dies ist eine Travel App mit der Nutzer weltweit kostenfreie oder billige Unterkünfte beim Reisen finden können.<br/>
            Die App hat ein traditionelles React Frontend und ein NodeJS Express Backend.<br/>
            User und Rooms werden in Mongo DB Atlas gespeichert. Bilder werden in der Datenbank FireBase von Google gespeichert.<br/>
            Es gibt eine Custom - sowie eine OAuth Authentifizierung mit Google.<br/>
            Desweiteren gibt es eine dreistufige Autorisierung (RBAC) mit der Nutzer und Apartments in einem Dashboard verwaltet werden können.<br/>
            Nutzer können Apartments anlegen, ansehen, editieren und löschen.<br/>
            Die App bietet eine Orts-Suchfunktion mittels Textsuche und einen Preisfilter um Apartments zu finden.<br/>
            Erstellt mithilfe eines Tutorials von Code Like Pro.`,

            `This is a travel app that allows users to find free or cheap accommodation worldwide when traveling.<br/>
            The app has a traditional React frontend and a NodeJS Express backend.<br/>
            Users and rooms are stored in Mongo DB Atlas. Images are stored in Google's FireBase database.<br/>
            It has custom and OAuth authentication with Google.<br/>
            Furthermore, there is a three-step authorization (RBAC) with which users and apartments can be managed in a dashboard.<br/>
            Users can create, view, edit and delete apartments.<br/>
            The app offers a location search function using text search and a price filter to find apartments.<br/>
            Created with the help of a tutorial from Code Like Pro.`,
        ],
        github: 'https://github.com/Shrike717/MERN-PLACE-TO-STAY',
        demo: 'https://mern-place-to-stay-client.vercel.app',
    },
];