import React from 'react';
import './experience.css';
import { BsPatchCheckFill } from 'react-icons/bs';
import { text } from '../../data/languages';

const Experience = ({ language }) => {
	return (
		<section id='experience'>
			<h5>{text[language].experience.h5_1}</h5>
			<h2>{text[language].experience.h2_1}</h2>

			<div className='container experience__container'>
				<div className='experience__frontend'>
					{/* CARD FRONTEND */}
					<h3>{text[language].experience.h3_1}</h3>
					<div className='experience__content'>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_1_1}</h4>
								<small className='text-light'>
									{text[language].experience.small_1_1}
								</small>
							</div>
						</article>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_1_2}</h4>
								<small className='text-light'>
									{text[language].experience.small_1_2}
								</small>
							</div>
						</article>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_1_3}</h4>
								<small className='text-light'>
									{text[language].experience.small_1_3}
								</small>
							</div>
						</article>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_1_4}</h4>
								<small className='text-light'>
									{text[language].experience.small_1_4}
								</small>
							</div>
						</article>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_1_5}</h4>
								<small className='text-light'>
									{text[language].experience.small_1_5}
								</small>
							</div>
						</article>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_1_6}</h4>
								<small className='text-light'>
									{text[language].experience.small_1_6}
								</small>
							</div>
						</article>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_1_7}</h4>
								<small className='text-light'>
									{text[language].experience.small_1_7}
								</small>
							</div>
						</article>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_1_8}</h4>
								<small className='text-light'>
									{text[language].experience.small_1_8}
								</small>
							</div>
						</article>
					</div>
				</div>

				{/* CARD BACKEND */}
				<div className='experience__backend'>
					<h3>{text[language].experience.h3_2}</h3>
					<div className='experience__content'>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_2_1}</h4>
								<small className='text-light'>
									{text[language].experience.small_2_1}
								</small>
							</div>
						</article>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_2_2}</h4>
								<small className='text-light'>
									{text[language].experience.small_2_2}
								</small>
							</div>
						</article>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_2_3}</h4>
								<small className='text-light'>
									{text[language].experience.small_2_3}
								</small>
							</div>
						</article>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_2_4}</h4>
								<small className='text-light'>
									{text[language].experience.small_2_4}
								</small>
							</div>
						</article>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_2_5}</h4>
								<small className='text-light'>
									{text[language].experience.small_2_5}
								</small>
							</div>
						</article>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_2_6}</h4>
								<small className='text-light'>
									{text[language].experience.small_2_6}
								</small>
							</div>
						</article>
					</div>
				</div>

				{/* CARD TOOLS */}
				<div className='experience__tools'>
					<h3>{text[language].experience.h3_3}</h3>
					<div className='experience__content'>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_3_1}</h4>
								<small className='text-light'>
									{text[language].experience.small_3_1}
								</small>
							</div>
						</article>
						<article className='experience__details'>
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>{text[language].experience.h4_3_2}</h4>
								<small className='text-light'>
									{text[language].experience.small_3_2}
								</small>
							</div>
						</article>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Experience;
