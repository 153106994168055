import React from 'react';
import './nav.css';
import { AiOutlineHome } from 'react-icons/ai';
import { AiOutlineUser } from 'react-icons/ai';
import { BiBook } from 'react-icons/bi';
import { BiBriefcase } from 'react-icons/bi';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const Nav = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [activeNav, setActiveNav] = useState('#');
	const isActiveHome = location.pathname === '/'; // This is used to determine if the current page is the homepage.

	// Explanation of the Link component functionality in the Nav component shown for the exaample 'About':
	// This Link component navigates to the homepage ('/') when clicked.
	// The default link behavior is prevented using event.preventDefault(). This prevents the page from reloading when the link is clicked.
	// The active navigation state is then set to '#about'.
	// The navigate function from the useNavigate hook is used to navigate to the homepage without a page reload.
	// After navigation, a setTimeout function is used to scroll to the 'about' section of the page.
	// This is done asynchronously to ensure that the navigation is complete before scrolling.
	// The className of the Link is conditionally set to 'active' if the current active navigation state is '#about'.
	// The AiOutlineUser icon from Ant Design Icons is used as the link's child component.

	return (
		<nav>
			{/* eslint-disable-next-line */}
			<Link
				to='/'
				onClick={(event) => {
					event.preventDefault();
					setActiveNav('#');
					navigate('/');
					setTimeout(() => window.scrollTo(0, 0), 0);
				}}
				className={isActiveHome && activeNav === '#' ? 'active' : ''} // This is used to determine if the current page is the homepage and the active navigation state is '#'.
				title='Home'
			>
				<AiOutlineHome />
			</Link>
			<Link
				to='/'
				onClick={(event) => {
					event.preventDefault();
					setActiveNav('#about');
					navigate('/');
					setTimeout(() => {
						document.getElementById('about').scrollIntoView();
					}, 0);
				}}
				className={activeNav === '#about' ? 'active' : ''}
				title='About'
			>
				<AiOutlineUser />
			</Link>
			<Link
				to='/'
				onClick={(event) => {
					event.preventDefault();
					setActiveNav('#experience');
					navigate('/');
					setTimeout(() => {
						document.getElementById('experience').scrollIntoView();
					}, 0);
				}}
				className={activeNav === '#experience' ? 'active' : ''}
				title='Experience'
			>
				<BiBook />
			</Link>
			<Link
				to='/'
				onClick={(event) => {
					event.preventDefault();
					setActiveNav('#portfolio');
					navigate('/');
					setTimeout(() => {
						document.getElementById('portfolio').scrollIntoView();
					}, 0);
				}}
				className={activeNav === '#portfolio' ? 'active' : ''}
				title='Portfolio'
			>
				<BiBriefcase />
			</Link>
			<Link
				to='/'
				onClick={(event) => {
					event.preventDefault();
					setActiveNav('#contact');
					navigate('/');
					setTimeout(() => {
						document.getElementById('contact').scrollIntoView();
					}, 0);
				}}
				className={activeNav === '#contact' ? 'active' : ''}
				title='Contact'
			>
				<BiMessageSquareDetail />
			</Link>
		</nav>
	);
};

export default Nav;
