import React from 'react';
import './about.css';
import MeAbout from '../../assets/me-about-01.jpg';
import { FaAward } from 'react-icons/fa';
import { HiUsers } from 'react-icons/hi';
import { VscFolderLibrary } from 'react-icons/vsc';
import { text } from '../../data/languages';

const About = ({ language }) => {
	return (
		<section id='about'>
			<h5>{text[language].about.h5_1}</h5>
			<h2>{text[language].about.h2_1}</h2>
			<div className='container about__container'>
				<div className='about__me'>
					<div className='about__me-img'>
						<img src={MeAbout} alt='About' />
					</div>
				</div>
				<div className='about__content'>
					<div className='about__cards'>
						<article className='about__card'>
							<FaAward className='about__icon' />
							<h5>{text[language].about.h5_2}</h5>
							<small>{text[language].about.small_1}</small>
						</article>

						<article className='about__card'>
							<HiUsers className='about__icon' />
							<h5>{text[language].about.h5_3}</h5>
							<small>{text[language].about.small_2}</small>
						</article>

						<article className='about__card'>
							<VscFolderLibrary className='about__icon' />
							<h5>{text[language].about.h5_4}</h5>
							<small>{text[language].about.small_3}</small>
						</article>
					</div>
					<article className='about__text'>
						<span>{text[language].about.p_1}</span>
						<br />
						<br />
						<span>{text[language].about.p_2}</span>
						<br />
						<span>{text[language].about.p_3}</span>
						<br />
						<span>{text[language].about.p_4}</span>
						<br />
						<span>{text[language].about.p_5}</span>
						<br />
						<br />
						<span>{text[language].about.p_6}</span>
						<br />
						<br />
						<span>{text[language].about.p_7}</span>
					</article>
					<a href='#contact' className='btn btn-primary'>
						{text[language].header.btnText}
					</a>
				</div>
			</div>
		</section>
	);
};

export default About;
